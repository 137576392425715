import React from 'react';

import Typography from '../typography/typography';

import './vehicle-description.scss';

interface VehicleDescriptionProps {
  make?: string;
  model?: string;
  renderAfterYearMake?: () => JSX.Element;
  rideHeight?: string;
  trim?: string;
  year?: string;
}

function VehicleDescription(props: VehicleDescriptionProps) {
  const { year, make, model, trim, rideHeight, renderAfterYearMake } = props;

  const shouldShowRideHeight = rideHeight && !/stock height/i.test(rideHeight);

  return (
    <div className="vehicle-description" styleName="container">
      {(year || make) && (
        <Typography
          className="vehicle-description__year-make-container"
          size="small"
          styleName="year-make-container"
          tag="div"
        >
          <Typography
            className="vehicle-description__year-make"
            styleName="vehicle-info"
            tag="span"
            variant="H5"
            weight="black"
          >
            {year} {make}
          </Typography>
          {renderAfterYearMake?.()}
        </Typography>
      )}
      {(model || trim) && (
        <div
          className="vehicle-description__model-trim-container"
          styleName="model-trim-container"
        >
          <Typography
            className="vehicle-description__model-trim"
            styleName="vehicle-info"
            tag="span"
          >
            {model} {trim}
          </Typography>
        </div>
      )}
      {shouldShowRideHeight && (
        <>
          <hr
            className="vehicle-description__ride-height-hr"
            styleName="ride-height-hr"
          />
          <Typography
            className="vehicle-description__ride-height"
            size="small"
            styleName="ride-height"
            tag="div"
          >
            {rideHeight}
          </Typography>
        </>
      )}
    </div>
  );
}

export default VehicleDescription;
