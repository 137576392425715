import React, { useCallback, useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';

import Button from '../../../../library/button/button';
import Modal from '../../../../library/modal/modal';
import TabAsHeader from '../../../../library/tab-as-header/tab-as-header';
import {
  createFitmentNotesTable,
  isFitmentTableNote
} from './fitment-notes-modal-utils';

import { GET_FITMENT_NOTES } from './graphql/fitment-notes-queries';

import { useMyStore } from '../../../../shared/hooks/hooks';

import { trackAction } from '../../../../shared/utils/analytics/analytics';
import { isArrayWithLength } from '../../../../shared/utils/validators';

import {
  AssemblyData,
  ChassisData
} from '../../../../shared/types/graphql-types';

import './fitment-notes-modal.scss';

const tabKeys = {
  'LIFT INSTRUCTIONS': 'LIFT INSTRUCTIONS',
  TPMS: 'TPMS',
  VEHICLE: 'VEHICLE'
};

const tabs = {
  [tabKeys.VEHICLE]: 'SPEC',
  [tabKeys.TPMS]: 'TPMS',
  [tabKeys['LIFT INSTRUCTIONS']]: 'LIFT'
};

interface FitmentNotesModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedFitment?: {
    assemblyAxles?: Array<AssemblyData>;
    chassis?: ChassisData;
    chassisId?: string;
    isGeneric?: boolean;
    trimId?: string;
    vehicleId?: string;
  };
}

function FitmentNotesModal(props: FitmentNotesModalProps) {
  const { isOpen, onClose, selectedFitment } = props;
  const { chassisId, isGeneric, trimId, vehicleId } = selectedFitment || {};
  const { myStore } = useMyStore();
  const [selectedTab, setSelectedTab] = useState(tabKeys.VEHICLE);
  const [notes, setNotes] = useState('');

  const { data: notesList } = useQuery(GET_FITMENT_NOTES, {
    skip: !chassisId || !trimId || !vehicleId || isGeneric,
    variables: {
      fitmentNotesInput: {
        categories: Object.values(tabs),
        chassisId,
        trimId,
        vehicleId
      },
      storeCode: myStore?.code
    }
  });

  const getNotesPerCategory = useCallback(
    category => {
      let text = '';
      const notesObject = notesList?.fitmentNotesWs?.getVehicleNotes?.find(
        notes => notes.category === tabs[category]
      );
      if (isArrayWithLength(notesObject?.notes)) {
        notesObject.notes.forEach(note => {
          if (isFitmentTableNote(note)) {
            text += createFitmentNotesTable(note);
          } else {
            const description = note.description.replaceAll('/n', '\n');
            text += `<pre>${text || ''} ${description}</pre>`;
          }
        });
      } else {
        text = `${category} ${
          category === tabKeys['LIFT INSTRUCTIONS'] ? '' : 'instructions'
        } are not available.`;
      }
      return text;
    },
    [notesList]
  );

  function onTabSelected(category) {
    setSelectedTab(category);
  }

  useEffect(() => {
    setNotes(getNotesPerCategory(selectedTab));
    if (isOpen) {
      let event;
      if (selectedTab === tabKeys.VEHICLE) {
        event = 'vehicle_fitment_notes_vehicle_notes';
      } else if (selectedTab === tabKeys.TPMS) {
        event = 'vehicle_fitment_notes_tpms_notes';
      } else if (selectedTab === tabKeys['LIFT INSTRUCTIONS']) {
        event = 'vehicle_fitment_notes_lift_notes';
      }
      trackAction(event);
    }
  }, [getNotesPerCategory, isOpen, selectedTab]);

  if (!selectedFitment || isGeneric) {
    return null;
  }
  const showRear = Boolean(selectedFitment?.assemblyAxles?.[1]);

  return (
    <Modal heading="VEHICLE FITMENT NOTES" isOpen={isOpen} onClose={onClose}>
      <div styleName="vehicle-info-container">
        <div styleName="vehicle-info-table vehicle-info-table--bordered">
          <div styleName="vehicle-info-content">
            <div styleName="vehicle-info-header-cell"> </div>
            <div styleName="vehicle-info-axle">{showRear ? 'Front' : ''}</div>
            {showRear && <div styleName="vehicle-info-axle">Rear</div>}
          </div>
          <div styleName="vehicle-info-content">
            <div styleName="vehicle-info-header-cell">Tire Pressure:</div>
            <div styleName="vehicle-info-cell">
              {Number(selectedFitment?.assemblyAxles?.[0].placardPSI)} psi
            </div>
            {showRear && (
              <div styleName="vehicle-info-cell">
                {Number(selectedFitment?.assemblyAxles?.[1].placardPSI)} psi
              </div>
            )}
          </div>

          <div styleName="vehicle-info-content">
            <div styleName="vehicle-info-header-cell">Wheel Width:</div>
            <div styleName="vehicle-info-cell">
              {selectedFitment?.assemblyAxles?.[0].wheelRimWidth}”
            </div>
            {showRear &&
              selectedFitment?.assemblyAxles?.[0].wheelRimWidth !==
                selectedFitment?.assemblyAxles?.[1].wheelRimWidth && (
                <div styleName="vehicle-info-cell">
                  {selectedFitment?.assemblyAxles?.[1].wheelRimWidth}”
                </div>
              )}
          </div>

          <div styleName="vehicle-info-content">
            <div styleName="vehicle-info-header-cell">Offset:</div>
            <div styleName="vehicle-info-cell">
              {selectedFitment?.assemblyAxles?.[0].offset} mm
            </div>
            {showRear &&
              selectedFitment?.assemblyAxles?.[0].offset !==
                selectedFitment?.assemblyAxles?.[1].offset && (
                <div styleName="vehicle-info-cell">
                  {selectedFitment?.assemblyAxles?.[1].offset} mm
                </div>
              )}
          </div>
        </div>

        <div styleName="vehicle-info-table">
          {selectedFitment?.chassis?.hubData?.lugTorque && (
            <div styleName="vehicle-info-content">
              <div styleName="vehicle-info-header-cell">Torque: </div>
              <div>{selectedFitment.chassis.hubData.lugTorque} ft-lbs</div>
            </div>
          )}
          {selectedFitment?.chassis?.chassisAxleList?.[0]?.boltPattern && (
            <div styleName="vehicle-info-content">
              <div styleName="vehicle-info-header-cell">Bolt Pattern:</div>
              <div>
                {selectedFitment?.chassis?.chassisAxleList?.[0].boltPattern} mm
              </div>
            </div>
          )}
          {selectedFitment?.chassis?.hubData?.threadDiameter &&
            selectedFitment?.chassis?.hubData?.pitch && (
              <div styleName="vehicle-info-content">
                <div styleName="vehicle-info-header-cell">Lug nut size:</div>
                <div>
                  {selectedFitment.chassis.hubData.threadDiameter} x{' '}
                  {selectedFitment.chassis.hubData.pitch}
                </div>
              </div>
            )}
          {selectedFitment?.chassis?.chassisAxleList?.[0]?.hubBore && (
            <div styleName="vehicle-info-content">
              <div styleName="vehicle-info-header-cell">Hub Bore: </div>
              <div>
                {selectedFitment?.chassis?.chassisAxleList?.[0].hubBore}
              </div>
            </div>
          )}
        </div>
      </div>

      <TabAsHeader
        onTabSelect={onTabSelected}
        selectedTab={selectedTab}
        styleName="tabs"
        tabs={Object.keys(tabs)}
      />
      <div styleName="container">
        <div styleName="contents">
          <div dangerouslySetInnerHTML={{ __html: notes }} />
        </div>
        <Button onClick={onClose} styleName="return-button">
          Return
        </Button>
      </div>
    </Modal>
  );
}

export default FitmentNotesModal;
