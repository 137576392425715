import gql from 'graphql-tag';

export const GET_FITMENT_NOTES = gql`
  query getFitmentNotes($fitmentNotesInput: FitmentNotesInput!) {
    fitmentNotesWs {
      getVehicleNotes(fitmentNotesInput: $fitmentNotesInput) {
        category
        notes {
          name
          description
        }
      }
    }
  }
`;
