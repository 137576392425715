function createFitmentNotesTableStructure(inputString) {
  const tableDelimiter = '\n\n';
  const rowDelimiter = '\n';

  // patterns that we do not want to split on whitespace
  const unsplitPatterns = [/AXLE TYPE:\s*\S+/g];

  return inputString.split(tableDelimiter).map(table => {
    const lines = table
      .split(rowDelimiter)
      .filter(line => line.trim().length > 0);

    return lines.map(line => {
      let newLine;
      // replace pattern matches with placeholders that do not contain spaces
      unsplitPatterns.forEach((pattern, index) => {
        newLine = line.replace(pattern, `PLACEHOLDER_${index}`);
      });

      const splitLine = newLine.trim().split(/\s+/);

      // restore original tokens that were replaced with placeholders
      return splitLine.map(token => {
        const placeholderMatch = token.match(/PLACEHOLDER_(\d+)/);
        if (placeholderMatch) {
          const patternIndex = parseInt(placeholderMatch[1]);
          const match = line.match(unsplitPatterns[patternIndex]);
          return match ? match[0] : token;
        }
        return token;
      });
    });
  });
}

function generateFitmentNotesTableHTML(grid) {
  if (!Array.isArray) {
    return null;
  }

  let htmlString = '<div class="fitment-notes-modal__table-container"><table>';

  grid.forEach((row, idx) => {
    const isHeader = row.every(
      cell => typeof cell === 'string' && isNaN(Number(cell))
    );

    let rowHTML = '';

    row.forEach(cell => {
      if (cell !== '') {
        rowHTML += `<${isHeader ? 'th' : 'td'} ${
          idx === 0 ? 'class="fitment-notes-modal__table-title"' : ''
        }>${cell}</${isHeader ? 'th' : 'td'}>`;
      }
    });

    htmlString += `<tr>${rowHTML}</tr>`;
  });

  htmlString += '</table></div>';

  return htmlString;
}

export function isFitmentTableNote(note) {
  return Boolean(note?.name?.includes('Offset'));
}

export function createFitmentNotesTable(note) {
  const tables = createFitmentNotesTableStructure(
    note.description.replaceAll('AXLETYP', 'AXLE TYPE')
  );

  return tables?.map(generateFitmentNotesTableHTML).join('<br />');
}
